<template>
  <div>
    <v-row
      v-if="viewData.id"
      justify="center"
    >
      <v-col
        cols="12"
        sm="12"
        md="10"
        lg="8"
        xl="7"
      >
        <v-row>
          <v-col
            cols="12"
            sm="12"
            md="6"
            lg="6"
          >
            <v-card
              outlined
              class="rounded-lg"
              :class="{
                'grey darken-3': $vuetify.theme.dark,
              }"
            >
              <v-card-text>
                <table class="company-info-table">
                  <thead>
                    <tr>
                      <th class="w-50" />
                      <th />
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td
                        colspan="2"
                        class="text-start"
                      >
                        <v-btn
                          small
                          depressed
                          @click="printInfo()"
                        >
                          <v-icon left>
                            mdil-printer
                          </v-icon>
                          {{ $t('Print') }}
                        </v-btn>
                      </td>
                    </tr>
                    <tr>
                      <td class="font-weight-bold">
                        {{ $t('Company Name') }}
                      </td>
                      <td>
                        {{ viewData.name }}
                      </td>
                    </tr>
                    <tr>
                      <td class="font-weight-bold">
                        {{ $t('Company Type') }}
                      </td>
                      <td>
                        {{ viewData.company_type.name }}
                      </td>
                    </tr>
                    <tr>
                      <td class="font-weight-bold">
                        {{ $t('Activity') }}
                      </td>
                      <td>
                        {{ viewData.activity }}
                      </td>
                    </tr>
                    <tr>
                      <td class="font-weight-bold">
                        {{ $t('Manager') }}
                      </td>
                      <td>
                        {{ viewData.manager }}
                      </td>
                    </tr>
                    <tr>
                      <td class="font-weight-bold">
                        {{ $t('Phone') }}
                      </td>
                      <td>
                        {{ viewData.phone }}
                      </td>
                    </tr>
                    <tr>
                      <td class="font-weight-bold">
                        {{ $t('Email') }}
                      </td>
                      <td>
                        {{ viewData.email }}
                      </td>
                    </tr>
                    <tr>
                      <td class="font-weight-bold">
                        {{ $t('Website') }}
                      </td>
                      <td>
                        {{ viewData.website }}
                      </td>
                    </tr>
                    <tr>
                      <td class="font-weight-bold">
                        {{ $t('Governorate') }}
                      </td>
                      <td>
                        {{ viewData.governorate.name }}
                      </td>
                    </tr>
                    <tr>
                      <td class="font-weight-bold">
                        {{ $t('First Bank') }}
                      </td>
                      <td>
                        {{ viewData.first_bank.name }}
                      </td>
                    </tr>
                    <tr>
                      <td class="font-weight-bold">
                        {{ $t('Account Bank') }}
                      </td>
                      <td>
                        {{ viewData.account_bank.name }}
                      </td>
                    </tr>
                    <tr>
                      <td class="font-weight-bold">
                        {{ $t('Business Name Date') }}
                      </td>
                      <td>
                        {{ viewData.name_date }}
                      </td>
                    </tr>
                    <!-- <tr>
                      <td class="font-weight-bold">
                      {{ $t('Business Name Number') }}
                      </td>
                      <td>
                        {{ viewData.name_number }}
                      </td>
                    </tr> -->
                    <tr>
                      <td class="font-weight-bold">
                        {{ $t('Capital') }}
                      </td>
                      <td>{{ $_number_format(viewData.capital) }}</td>
                    </tr>
                    <tr>
                      <td class="font-weight-bold">
                        {{ $t('Registration Number') }}
                      </td>
                      <td>
                        {{ viewData.company_registration_number }}
                      </td>
                    </tr>
                    <tr>
                      <td class="font-weight-bold">
                        {{ $t('Company Registration Date') }}
                      </td>
                      <td>{{ $_date_format(viewData.company_registration_date) }}</td>
                    </tr>
                    <tr>
                      <td class="font-weight-bold">
                        {{ $t('Unique Company Business No.') }}
                      </td>
                      <td>
                        {{ viewData.manager_shareholder_business_no }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col
            cols="12"
            sm="12"
            md="6"
            lg="6"
          >
            <v-row>
              <v-col cols="12">
                <v-card
                  outlined
                  class="rounded-lg"
                  :class="{
                    'grey darken-3': $vuetify.theme.dark,
                  }"
                >
                  <v-card-text>
                    <table class="company-info-table">
                      <thead>
                        <tr>
                          <th class="w-60" />
                          <th />
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td
                            colspan="2"
                            class="font-weight-bold text-start pb-3"
                          >
                            {{ $t('Dos Numbers') }}
                          </td>
                        </tr>
                        <!-- <tr>
                          <td class="font-weight-bold">
                          {{ $t('Supporter Accountant') }}
                          </td>
                          <td>
                            {{ viewData.s_accountant }}
                          </td>
                        </tr> -->
                        <!-- <tr>
                          <td class="font-weight-bold">
                          {{ $t('Worker Accountant') }}
                          </td>
                          <td>
                            {{ viewData.w_accountant }}
                          </td>
                        </tr> -->
                        <!-- <tr>
                          <td class="font-weight-bold">
                          {{ $t('Accountant Contract Date') }}
                          </td>
                          <td>
                            {{ viewData.accountant_contract_date }}
                          </td>
                        </tr> -->
                        <!-- <tr>
                          <td class="font-weight-bold">
                          {{ $t('Supporter Lawyer') }}
                          </td>
                          <td>
                            {{ viewData.s_lawyer }}
                          </td>
                        </tr> -->
                        <!-- <tr>
                          <td class="font-weight-bold">
                          {{ $t('Worker Lawyer') }}
                          </td>
                          <td>
                            {{ viewData.w_lawyer }}
                          </td>
                        </tr> -->
                        <!-- <tr>
                          <td class="font-weight-bold">
                          {{ $t('Lawyer Contract Date') }}
                          </td>
                          <td>
                            {{ viewData.lawyer_contract_date }}
                          </td>
                        </tr> -->
                        <tr>
                          <td class="font-weight-bold">
                            {{ $t('Dos Number In Companies') }} / {{ $t(viewData.dos_number_place_text) }}
                          </td>
                          <td>
                            {{ viewData.dos_number_in_co }}
                          </td>
                        </tr>
                        <tr>
                          <td class="font-weight-bold">
                            {{ $t('Dos Number In Companies/General') }}
                          </td>
                          <td>
                            {{ viewData.dos_number_in_co_general }}
                          </td>
                        </tr>
                        <tr>
                          <td class="font-weight-bold">
                            {{ $t('Dos Number In Tax Office') }} / {{ $t(viewData.dos_number_place_text) }}
                          </td>
                          <td>
                            {{ viewData.dos_number_in_tax_office }}
                          </td>
                        </tr>
                        <tr>
                          <td class="font-weight-bold">
                            {{ $t('Real Beneficiary Form Number') }}
                          </td>
                          <td>
                            {{ viewData.real_beneficiary_form_number }}
                          </td>
                        </tr>
                        <tr>
                          <td class="font-weight-bold">
                            {{ $t('Social Security Employee Number') }} / {{ $t(viewData.dos_number_place_text) }}
                          </td>
                          <td>
                            {{ viewData.social_security_number_employee }}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </v-card-text>
                </v-card>
              </v-col>

              <v-col cols="12">
                <v-card
                  outlined
                  class="rounded-lg"
                >
                  <v-card-text>
                    <v-row
                      no-gutters
                      class="mt-3"
                    >
                      <v-col
                        cols="12"
                        class="font-weight-bold pb-2"
                      >
                        {{ $t('Shareholders') }}
                      </v-col>

                      <v-col
                        v-for="(shareholder, index) in viewData.shareholders"
                        :key="shareholder.id"
                        cols="12"
                        class="mt-1 mb-1"
                      >
                        <div
                          class="d-flex justify-space-between align-center rounded px-2 py-1"
                          :style="`background-color: ${shareholderColors[index]}`"
                        >
                          <!-- :class="{
                            'green lighten-3': index == 0 && !$vuetify.theme.dark,
                            'blue lighten-3': index == 1 && !$vuetify.theme.dark,
                            'warning lighten-3': index == 2 && !$vuetify.theme.dark,
                            'green darken-1': index == 0 && $vuetify.theme.dark,
                            'blue darken-1': index == 1 && $vuetify.theme.dark,
                            'warning darken-1': index == 2 && $vuetify.theme.dark,
                          }" -->
                          <div>
                            <v-avatar size="34">
                              <img
                                :src="shareholder.profile_picture ? `${$_file_path()}${shareholder.profile_picture}?token=${$_auth_token()}`: $_profile_placeholder()"
                                :alt="shareholder.username"
                              >
                            </v-avatar>
                            {{ shareholder.first_name }}
                          </div>

                          <div>
                            %{{ shareholder.share_percentage }}
                          </div>
                        </div>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import axios from 'axios'
export default {
  data() {
    return {
      viewData: {},
      shareholderColors: [
        '#4CAF506F',
        '#2196F36F',
        '#FF98006F',
        '#0096886F',
        '#3F51B56F',
        '#E91E636F',
        '#9C27B06F',
        '#673AB76F',
      ]
    }
  },

  mounted() {
    this.getCompanyData()
  },

  methods: {
    async getCompanyData() {
      await axios.get(`company/${this.$route.params.id}/`).then(res => {
        this.viewData = res.data
      })
    },

    printInfo() {
      let content = {
        content: '',
        css: ''
      }

      content.content = `
        <div style="width: 100%" class="px-6" class="white">
          <table
            cellspacing="0"
            style="width: 100%; border: 1px solid #aaa; border-radius: 2px; border-bottom: 0px"
          >
            <tr>
              <td class="bottom-border pa-2"><b>${this.$t('Name')}:</b> ${this.viewData.name}</td>
              <td class="bottom-border pa-2"><b>${this.$t('Company Type')}:</b> ${this.viewData.company_type.name}</td>
            </tr>
           
           <tr>
              <td class="bottom-border pa-2"><b>${this.$t('Governorate')}:</b> ${this.viewData.governorate.name}</td>
              <td class="bottom-border pa-2"><b>${this.$t('First Bank')}:</b> ${this.viewData.first_bank.name}</td>
            </tr>
           
           <tr>
              <td class="bottom-border pa-2"><b>${this.$t('Account Bank')}:</b> ${this.viewData.account_bank.name}</td>
              <td class="bottom-border pa-2"><b>${this.$t('Business Name Date')}:</b> ${this.viewData.name_date}</td>
            </tr>
           
           <tr>
              <td class="bottom-border pa-2"><b>${this.$t('Registration Number')}:</b> ${this.viewData.company_registration_number ?? ''}</td>
              <td class="bottom-border pa-2"><b>${this.$t('Company Registration Date')}:</b> ${this.$_date_format(this.viewData.company_registration_date)}</td>
            </tr>

            <tr>
              <td class="bottom-border pa-2"><b>${this.$t('Manager')}:</b> ${this.viewData.manager ?? ''}</td>
              <td class="bottom-border pa-2"><b>${this.$t('Phone')}:</b> ${this.viewData.phone}</td>
            </tr>

            <tr>
              <td class="bottom-border pa-2"><b>${this.$t('Email')}:</b> ${this.viewData.email ?? ''}</td>
              <td class="bottom-border pa-2"><b>${this.$t('Website')}:</b> ${this.viewData.website ?? ''}</td>
            </tr>

            <tr>
              <td class="bottom-border pa-2"><b>${this.$t('Dos Number In Companies/Slemany')}:</b> ${this.viewData.dos_number_in_co ?? ''}</td>
              <td class="bottom-border pa-2"><b>${this.$t('Dos Number In Tax Office')}:</b> ${this.viewData.dos_number_in_tax_office ?? ''}</td>
            </tr>

            <tr>
              <td class="bottom-border pa-2"><b>${this.$t('SL')}:</b> ${this.viewData.sl ?? ''}</td>
              <td class="bottom-border pa-2"><b>${this.$t('FCC')}:</b> ${this.viewData.fcc ?? ''}</td>
            </tr>

            <tr>
              <td colspan="2" class="bottom-border pa-2"><b>${this.$t('Activity')}:</b> ${this.viewData.activity ?? ''}</td>
            </tr>
          </table>
        </div>
      `

      content.css = `
        .bottom-border {
          border-bottom: 1px solid #aaa
        }
      `

      this.$_manual_print(content)
    }
  },
}
</script>

<style lang="scss">
.company-info-table tbody > tr > td {
  padding: 0.4rem 0.2rem;
}
</style>